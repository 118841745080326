<template>
  <div>
    <Header></Header>
    <div>

      <div>
        <ul>
          <li
            class="b2"
            id="b_2"
          >
            <a href="#a2">
              <el-tooltip
                class="item"
                effect="light"
                content="主题场景设计"
                placement="left"
              >
                <div class="b2_2">
                  <img
                    :src="index_img1"
                    class="img6 img_6_3 side1 "
                    @mouseover="change1"
                    @mouseout="changeIndex1"
                  >
                  <img
                    src="./zhwc_img/ICON (5).png"
                    class="img6 img_6_3 side1_1"
                    v-show="side1_1"
                  >
                </div>
              </el-tooltip>
            </a>
          </li>
          <li
            class="b3"
            id="b_3"
          >
            <a href="#a3">
              <el-tooltip
                class="item"
                effect="light"
                content="主题餐饮产品设计"
                placement="left"
              >
                <div class="b3_3">
                  <img
                    :src="index_img2"
                    class="img6 img_6_2 side2"
                    @mouseover="change2"
                    @mouseout="changeIndex2"
                  >
                  <img
                    src="./zhwc_img/img(3).png"
                    class="img6 img_6_2 side2_2"
                    v-show="side2_1"
                  >
                </div>

              </el-tooltip>
            </a>
          </li>
          <li
            class="b4"
            id="b_4"
          >
            <a href="#a4">
              <el-tooltip
                class="item"
                effect="light"
                content="衍生品开发"
                placement="left"
              >
                <div class="b4_4">
                  <img
                    :src="index_img3"
                    class="img6 img_6_4 side3"
                    @mouseover="change3"
                    @mouseout="changeIndex3"
                  >
                  <img
                    src="./zhwc_img/img(2).png"
                    class="img6 img_6_4 side3_3"
                    v-show="side3_1"
                  >
                </div>
              </el-tooltip>
            </a>
          </li>
          <li
            class="b5"
            id="b_5"
          >
            <a href="#a5">
              <el-tooltip
                class="item"
                effect="light"
                content="文旅二消升级"
                placement="left"
              >
                <div class="b5_5">
                  <img
                    :src="index_img4"
                    class="img6 img_6_5 side4"
                    @mouseover="change4"
                    @mouseout="changeIndex4"
                  >
                  <img
                    src="./zhwc_img/img(1).png"
                    class="img6 img_6_5 side4_4"
                    v-show="side4_1"
                  >
                </div>
              </el-tooltip>
            </a>
          </li>
          <li
            class="b1"
            id="b_1"
          >
            <a href="#a1">
              <el-tooltip
                class="item"
                effect="light"
                content="回到顶部"
                placement="left"
              >
                <div class="b1_1">
                  <img
                    :src="index_img5"
                    class="img6 img_6_1"
                    id="side5"
                    @mouseover="change5"
                    @mouseout="changeIndex5"
                  >
                </div>
              </el-tooltip>
            </a>
          </li>
        </ul>

      </div>
      <!-- <div id="a1"  class="a_1"  v-lazy:background-image="require('./zhwc_img/banner.png')"> -->
      <div
        id="a1"
        class="a_1"
      >
        <div class="start">
          <img
            v-lazy='require("./zhwc_img/a1kcKZFm.webp")'
            class="start_img"
          >
        </div>

        <div class="bannert">
          <div class="txt11">智慧文创</div>
          <div class="word11">
            用年轻人的优秀创意搭建一座时空桥梁，把千年前古人和现代人的生活方式做一个连接，创造出属于这个时代的文物时尚
          </div>
        </div>
      </div>
      <div
        id="a2"
        class="a_2"
      >
        <div class="txt12">
          主题场景设计
        </div>
        <div class="word12">
          探索文创新空间，紧随新时代潮流，丰富多元化主题场景，展现文创新的生命力
        </div>

        <div class="img1_2">
          <div>
            <div class="img-1">
              <img
                v-lazy="require('./zhwc_img/YssyTOtv.webp')"
                class="img1_2_1"
              >
            </div>

            <div class="txt1_2_1">
              从全方位多角度打造的沉浸式文创主题街区设计
            </div>
          </div>
          <div class="txt1_2">
            <div class="img-1">
              <img
                v-lazy="require('./zhwc_img/dJKjMMVZ.webp')"
                class="img1_2_1"
              >
            </div>
            <div class="txt1_2_3">
              <div class="txt1_2_2">
                围绕标志性、可逛性、主题性、独创性贴合文化的融合形式进行多元化设计
              </div>

            </div>
          </div>
        </div>
      </div>
      <div
        id="a3"
        class="a_3"
      >
        <div class="txt3">
          主题餐饮产品设计
        </div>
        <div class="word12">文创餐饮引领新吃法，融合历史、文化、体验、情趣、故事等多元主题，创造更多新生代的美食文化</div>
        <div class="img-2">
          <img
            v-lazy="require('./zhwc_img/8nmEGXzt.webp')"
            class="img_3"
          >

        </div>

      </div>

      <div
        id="a4"
        class="a_4"
      >
        <div class="txt12">衍生品开发</div>
        <div class="word12">对文化资源、文化用品进行创造与提升，通过知识产权的开发和运用，创意产品</div>
        <div class="img_4_1_1">
          <div>
            <div class="img-3">
              <img
                v-lazy="require('./zhwc_img/xnOAO7CX.webp')"
                class="img_4"
              >
            </div>

            <div class="txt_4_1">
              <span class="txt_4">南京1912奎斯艺术装置展及创意市集</span>
            </div>
          </div>
          <div class="img_4_2">
            <div class="img-3">
              <img
                v-lazy="require('./zhwc_img/FBzcYrKn.webp')"
                class="img_4"
              >
            </div>
            <div class="txt_4_1">
              <span class="txt_4">金茂汇引力法则新媒体艺术联展</span>
            </div>
          </div>
          <div class="img_4_2">
            <div class="img-3">
              <img
                v-lazy="require('./zhwc_img/NzTbXHNA.webp')"
                class="img_4"
              >
            </div>
            <div class="txt_4_1">
              <span class="txt_4">上海思南公馆非遗年礼节</span>
            </div>
          </div>
        </div>
      </div>

      <div
        id="a5"
        class="a_5"
      >
        <div class="txt3">
          文旅二消升级
        </div>
        <div class="word12">景区、特色小镇、主题乐园文旅地产打造独特体验商业项目提示二次消费项目策划设计</div>
        <div class="img5 mt6">
          <div class="img_5_8">
            <div>
              <img
                src="./zhwc_img/ICON .png"
                class="img_5_1_1"
              >
            </div>
            <div class="txt5">
              <span class="txt_5">
                特色主题网红商业
              </span>
              <span class="txt_5_1">对文旅品牌特色商业拥的敏锐触觉和领先经验</span>
            </div>
          </div>
          <div class="img_5_8 ml">
            <div>
              <img
                src="./zhwc_img/ICON(2).png"
                class="img_5_1_1"
              >
            </div>
            <div class="txt5">
              <span class="txt_5">
                特色商业运营规划
              </span>
              <span class="txt_5_1">主题场景打造、产品研发、运营体系构建</span>
            </div>
          </div>
        </div>
        <div class="img5 mt">
          <div class="img_5_8">
            <div>
              <img
                src="./zhwc_img/ICON(1).png"
                class="img_5_1_1"
              >
            </div>
            <div class="txt5">
              <span class="txt_5">
                体验特色项目
              </span>
              <span class="txt_5_1">消费项目调研、消费项目分析、定位、内容体系建立</span>
            </div>
          </div>
          <div class="img_5_8 ml">
            <div>
              <img
                src="./zhwc_img/ICON(3).png"
                class="img_5_1_1"
              >
            </div>
            <div class="txt5">
              <span class="txt_5">
                特色商品盈利方案
              </span>
              <span class="txt_5_1">提供营销活动策划执行、无忧的运营、景区增收新选择</span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
function over() {

}
</script>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      index_img1: require("./zhwc_img/ICON (1).png"),
      index_img11: require("./zhwc_img/img_5.png"),
      index_img111: require("./zhwc_img/ICON (1).png"),
      index_img2: require("./zhwc_img/ICON (4).png"),
      index_img22: require("./zhwc_img/img_3.png"),
      index_img222: require("./zhwc_img/ICON (4).png"),
      index_img3: require("./zhwc_img/ICON (3).png"),
      index_img33: require("./zhwc_img/img_2.png"),
      index_img333: require("./zhwc_img/ICON (3).png"),
      index_img4: require("./zhwc_img/ICON (2).png"),
      index_img44: require("./zhwc_img/img_1.png"),
      index_img444: require("./zhwc_img/ICON (2).png"),
      index_img5: require("./zhwc_img/ICON.png"),
      index_img55: require("./zhwc_img/img_6.png"),
      index_img555: require("./zhwc_img/ICON.png"),
      side1_1: false,
      side2_1: false,
      side3_1: false,
      side4_1: false,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.sidebar)
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.sidebar)
  },
  methods: {
    sidebar() {
      let scrollTop = 0;
      let distance2 = document.querySelector(".a_2")
      let distanceTop2 = distance2.offsetTop - 10
      let distance3 = document.querySelector(".a_3")
      let distanceTop3 = distance3.offsetTop - 10
      let distance4 = document.querySelector(".a_4")
      let distanceTop4 = distance4.offsetTop - 10
      let distance5 = document.querySelector(".a_5")
      let distanceTop5 = distance5.offsetTop - 10
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }

      if (scrollTop < 1000) {
        document.querySelector(".header").style.backgroundColor = "";
      } else {
        document.querySelector(".header").style.backgroundColor = "black";
      }
      if (scrollTop < distanceTop2) {
        this.side1_1 = false
        this.side2_1 = false
        this.side3_1 = false
        this.side4_1 = false
        document.querySelector(".b2").style.backgroundColor = "";
        document.querySelector(".b3").style.backgroundColor = "";
        document.querySelector(".b4").style.backgroundColor = "";
        document.querySelector(".b5").style.backgroundColor = "";
        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "block"
        document.querySelector(".side4").style.display = "block"
      } else if (scrollTop >= distanceTop2 && scrollTop <= distanceTop3) {
        document.querySelector(".b1").style.backgroundColor = "";
        document.querySelector(".b2").style.backgroundColor = "#007AFF";
        document.querySelector(".b3").style.backgroundColor = "";
        document.querySelector(".b4").style.backgroundColor = "";
        document.querySelector(".b5").style.backgroundColor = "";
        document.querySelector(".side1").style.display = "none"
        document.querySelector(".side1_1").style.display = "block"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "block"

        this.side1_1 = true
        this.side2_1 = false
        this.side3_1 = false
        this.side4_1 = false

      } else if (scrollTop >= distanceTop3 && scrollTop <= distanceTop4) {
        document.querySelector(".b1").style.backgroundColor = "";
        document.querySelector(".b2").style.backgroundColor = "";
        document.querySelector(".b3").style.backgroundColor = "#007AFF";
        document.querySelector(".b4").style.backgroundColor = "";
        document.querySelector(".b5").style.backgroundColor = "";
        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "none"
        document.querySelector(".side3").style.display = "block"
        document.querySelector(".side4").style.display = "block"
        this.side1_1 = false
        this.side2_1 = true
        this.side3_1 = false
        this.side4_1 = false
      } else if (scrollTop >= distanceTop4 && scrollTop <= distanceTop5) {
        document.querySelector(".b1").style.backgroundColor = "";
        document.querySelector(".b2").style.backgroundColor = "";
        document.querySelector(".b3").style.backgroundColor = "";
        document.querySelector(".b4").style.backgroundColor = "#007AFF";
        document.querySelector(".b5").style.backgroundColor = "";
        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "none"
        document.querySelector(".side4").style.display = "block"
        this.side1_1 = false
        this.side2_1 = false
        this.side3_1 = true
        this.side4_1 = false
      } else if (scrollTop >= distanceTop5) {
        document.querySelector(".b1").style.backgroundColor = "";
        document.querySelector(".b2").style.backgroundColor = "";
        document.querySelector(".b3").style.backgroundColor = "";
        document.querySelector(".b4").style.backgroundColor = "";
        document.querySelector(".b5").style.backgroundColor = "#007AFF";
        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "block"
        document.querySelector(".side4").style.display = "none"

        this.side1_1 = false
        this.side2_1 = false
        this.side3_1 = false
        this.side4_1 = true
      }


    },
    change1() {
      this.index_img1 = this.index_img11
    },
    change2() {
      this.index_img2 = this.index_img22
    },
    change3() {
      this.index_img3 = this.index_img33
    },
    change4() {
      this.index_img4 = this.index_img44
    },
    change5() {
      this.index_img5 = this.index_img55
    },
    changeIndex1() {
      this.index_img1 = this.index_img111
    },
    changeIndex2() {
      this.index_img2 = this.index_img222
    },
    changeIndex3() {
      this.index_img3 = this.index_img333
    },
    changeIndex4() {
      this.index_img4 = this.index_img444
    },
    changeIndex5() {
      this.index_img5 = this.index_img555
    },
  }

}
</script>
<style>
.el-tooltip__popper {
  font-size: 20px;
}
</style>
<style lang="scss" scoped>
ul {
  z-index: 999;
}
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.a_1 {
  height: 600px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.start_img {
  height: 600px;
  width: 100%;
}
.start {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
}
.txt11 {
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  letter-spacing: 3.4285714626312256px;
  line-height: 67px;
  text-align: justify;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}
.bannert {
  position: absolute;
  left: 361px;
  top: 230px;
}
.txt11 {
  animation: sport 0.5s;
  transform: translateY(0px);
}

.word11 {
  width: 846px;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  line-height: 33px;
  text-align: justify;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  margin-top: 24px;
}
.word11 {
  animation: sport 0.5s;
  transform: translateY(0px);
}
.txt12 {
  width: 248px;
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  letter-spacing: 1.25px;
  line-height: 56px;
  padding-top: 80px;
  margin: auto;
}
.word12 {
  /* width: 735px; */
  color: rgba(86, 86, 86, 1);
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
  padding-top: 24px;
  margin: auto;
}
.img1_2 {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.a_2 {
  height: 878px;
}
.img1_2_1 {
  height: 585px;
  height: 420px;
}
.img-1 {
  height: 585px;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 100px;
    height: 100px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 585px;
    height: 420px;
  }
}
.txt1_2_1 {
  height: 98px;
  border-radius: 0 0 6px 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  letter-spacing: 0.75px;
  line-height: 98px;
  text-align: center;
}
.txt1_2 {
  margin-left: 30px;
}
.txt1_2_3 {
  height: 98px;
  border-radius: 0 0 6px 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  letter-spacing: 0.75px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.txt1_2_2 {
  width: 400px;
}
.a_3 {
  height: 780px;
  background-color: rgba(246, 248, 251, 1);
}
.txt3 {
  width: 330px;
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  letter-spacing: 1.25px;
  line-height: 56px;
  padding-top: 80px;
  margin: auto;
}
.img_3 {
  width: 1200px;
  height: 420px;
}
.img-2 {
  margin: auto;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 100px;
    height: 100px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    width: 1200px;
    height: 420px;
  }
}
.img_4_1_1 {
  margin-top: 60px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}
.a_4 {
  height: 693px;
}
.img_4 {
  width: 380px;
  height: 260px;
}
.img-3 {
  width: 380px;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 50px;
    height: 50px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    width: 380px;
    height: 260px;
  }
}
.txt_4 {
  /* width: 263px; */
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  letter-spacing: 0.75px;
  line-height: 25px;
}
.txt_4_1 {
  height: 73px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_4_2 {
  margin-left: 30px;
}
.a_5 {
  height: 690px;
  background-color: rgba(246, 248, 251, 1);
}
.img_5_8 {
  height: 150px;
  border-radius: 0 0 6px 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 585px;
  display: flex;
  align-items: center;
  padding-left: 52px;
}
.img5 {
  display: flex;
  justify-content: center;
}
.img_5_1 {
  width: 48px;
  height: 48px;
}
.txt_5 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  letter-spacing: 0.8333333134651184px;
  line-height: 28px;
  text-align: left;
  align-self: flex-start;
}
.txt_5_1 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 18px;
  letter-spacing: 0.5833333134651184px;
  line-height: 20px;
  text-align: left;
  align-self: center;
  margin-top: 16px;
}
.txt5 {
  margin-left: 36px;
}
.mt {
  margin-top: 30px;
}
.ml {
  margin-left: 30px;
}
ul {
  position: fixed;
  right: 0;
  top: 241px;
}
li {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
li:hover {
  background-color: #fff;
}
.img6 {
  height: 32px;
  width: 32px;
}
.mt6 {
  margin-top: 60px;
}
#b_2 :hover img {
  content: url("./zhwc_img/img_5.png");
}
#b_3 :hover img {
  content: url("./zhwc_img/img_3.png");
}
#b_4 :hover img {
  content: url("./zhwc_img/img_2.png");
}
#b_5 :hover img {
  content: url("./zhwc_img/img_1.png");
}
#b_1 :hover img {
  content: url("./zhwc_img/img_6.png");
}
/* .b2{
  width: 64px;
  height: 64px;
  background: #fff url("./zhwc_img/ICON (1).png") no-repeat center center;
  border-style: auto;
}
.b2_2:hover{
  background: url("./zhwc_img/img_5.png") no-repeat center center;
}
.b3_3{
  background: url("./zhwc_img/ICON／(4).png") no-repeat center center;
}
.b3_3:hover{
  background: url("./zhwc_img/img_3.png") no-repeat center center;
}
.b4_4{
  background: url("./zhwc_img/ICON (3).png") no-repeat center center;
}
.b4_4:hover{
  background: url("./zhwc_img/img_2.png") no-repeat center center;
}
.b5_5{
  background: url("./zhwc_img/ICON (2).png") no-repeat center center;
}
.b5_5:hover{
  background: url("./zhwc_img/img_1.png") no-repeat center center;
} */
</style>